import {LinkProps} from "next/link";
import React, {MouseEventHandler} from "react";

import Glimmer, {GlimmerOffset} from "../Glimmer";
import Interaction from "./Interaction";
import LeftIcon from "./LeftIcon";
import Line from "./Line";
import RightIcon from "./RightIcon";
import SlotTime from "./SlotTime";
import Tags from "./Tags";
import {CareEntryRowVariant} from ".";

type PresentationProps = {
  query?: string;
  href?: LinkProps["href"] | null;
  onClick?: MouseEventHandler;
  icon: string;
  line1: string;
  line2?: string;
  price?: string;
  turnaround?: string;
  isSlotLoading?: boolean;
  readableSlotTime?: string;
  variant: CareEntryRowVariant;
  isLoading?: boolean;
  withBorder?: boolean;
  cypressKey?: string;
  role?: "option";
  ["aria-selected"]?: boolean;
};

const styles = {
  icon: {borderRadius: "50%", flexShrink: 0},
};

const Sm = React.memo(
  ({
    icon,
    line1,
    readableSlotTime,
    query,
    isSlotLoading,
    isLoading = false,
  }: Pick<PresentationProps, "query" | "icon" | "line1" | "isSlotLoading" | "readableSlotTime"> & {
    isLoading?: boolean;
  }) => (
    <div className="flex aic justify-start py-3">
      {isLoading ? (
        <>
          <div className="px-2 flex justify-center items-center">
            <Glimmer height={24} width={24} style={styles.icon} offset={GlimmerOffset.XXS} />
          </div>
          <div className="grid grid-flow-row gap-1 pr-4 w-full">
            <Glimmer height={30} width="100%" />
          </div>
        </>
      ) : (
        <>
          <LeftIcon icon={icon} className="px-2 py-0" size="sm" />
          <span className="ml-1 flex justify-start flex-wrap w-full first:mr-2 py-1 aic flex-col items-start sm:flex-row sm:items-center">
            <Line keyPrefix="name" className="mr-2" text={line1} query={query} />
            <SlotTime
              className="fs14"
              readableSlotTime={readableSlotTime}
              isLoading={isSlotLoading}
            />
          </span>
          <RightIcon className="minw2-f mr2-f" />
        </>
      )}
    </div>
  ),
);

const Md = React.memo(
  ({
    query,
    icon,
    line1,
    price,
    turnaround,
    isSlotLoading,
    readableSlotTime,
    isLoading = false,
  }: Pick<
    PresentationProps,
    "query" | "icon" | "line1" | "price" | "turnaround" | "isSlotLoading" | "readableSlotTime"
  > & {isLoading?: boolean}) => (
    <div className="flex justify-between items-center py-2" data-cy="search-result">
      {isLoading ? (
        <>
          <div className="px-2 flex justify-center">
            <Glimmer height={37} width={37} style={styles.icon} offset={GlimmerOffset.XXS} />
          </div>
          <div className="grid grid-flow-row gap-1 w-full pr-4">
            <Glimmer height={30} width="100%" />
            <Glimmer height={18} width={200} />
          </div>
        </>
      ) : (
        <>
          <LeftIcon icon={icon} size="md" className="px-4 py-2" />
          <span className="flex flex-col justify-center w-full">
            <Line keyPrefix="line1" className="mb-1" text={line1} query={query} />
            <Tags
              turnaround={turnaround}
              price={price}
              isSlotLoading={isSlotLoading}
              readableSlotTime={readableSlotTime}
            />
          </span>
          <RightIcon />
        </>
      )}
    </div>
  ),
);

const Lg = React.memo(
  ({
    query,
    icon,
    line1,
    line2,
    price,
    turnaround,
    isSlotLoading,
    readableSlotTime,
    isLoading = false,
  }: Pick<
    PresentationProps,
    | "query"
    | "icon"
    | "line1"
    | "line2"
    | "price"
    | "turnaround"
    | "isSlotLoading"
    | "readableSlotTime"
  > & {isLoading?: boolean}) => (
    <div className="flex justify-between items-center py-4">
      {isLoading ? (
        <>
          <div className="px-2 flex justify-center">
            <Glimmer height={40} width={40} style={styles.icon} offset={GlimmerOffset.XXS} />
          </div>
          <div className="grid grid-flow-row gap-1 pr-4 w-full">
            <Glimmer height={30} width="100%" />
            <Glimmer height={24} width="50%" />
            <Glimmer height={16} width={100} />
          </div>
        </>
      ) : (
        <>
          <LeftIcon icon={icon} size="lg" />
          <span className="flex flex-col justify-center w-full">
            <Line keyPrefix="line1" className="mb-2" text={line1} query={query} />
            {line2 && (
              <Line
                keyPrefix="line2"
                className="font-ir-f mb-1 !text-sm"
                text={line2}
                query={query}
              />
            )}
            <Tags
              turnaround={turnaround}
              price={price}
              isSlotLoading={isSlotLoading}
              readableSlotTime={readableSlotTime}
            />
          </span>
          <RightIcon />
        </>
      )}
    </div>
  ),
);

Sm.displayName = "SmCareEntryListRow";
Md.displayName = "MdCareEntryListRow";
Lg.displayName = "LgCareEntryListRow";

const CareEntryRow = ({
  query,
  href,
  onClick,
  icon,
  line1,
  line2,
  price,
  turnaround,
  isSlotLoading,
  readableSlotTime,
  variant,
  isLoading = false,
  cypressKey,
  role,
  ["aria-selected"]: ariaSelected,
}: PresentationProps) => (
  <Interaction
    href={href}
    onClick={onClick}
    isBold={Boolean(query)}
    disabled={isLoading}
    cypressKey={cypressKey}
    role={role}
    aria-selected={ariaSelected}
  >
    {variant === CareEntryRowVariant.LG ? (
      <Lg
        query={query}
        icon={icon}
        line1={line1}
        line2={line2}
        price={price}
        turnaround={turnaround}
        readableSlotTime={readableSlotTime}
        isSlotLoading={isSlotLoading}
        isLoading={isLoading}
      />
    ) : variant === CareEntryRowVariant.MD ? (
      <Md
        query={query}
        icon={icon}
        line1={line1}
        price={price}
        turnaround={turnaround}
        readableSlotTime={readableSlotTime}
        isSlotLoading={isSlotLoading}
        isLoading={isLoading}
      />
    ) : (
      <Sm
        query={query}
        icon={icon}
        line1={line1}
        readableSlotTime={readableSlotTime}
        isSlotLoading={isSlotLoading}
        isLoading={isLoading}
      />
    )}
  </Interaction>
);

CareEntryRow.displayName = "CareEntryRow";
export default React.memo(CareEntryRow);
