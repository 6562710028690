import {useMemo} from "react";

import {useSoonestSlotByReasonSlugsQuery} from "../../../queries/useSoonestSlotByReasonSlug";
import {useTypedSelector} from "../../../store";
import {RootStateLocation} from "../../../store/types";
import {ExtendedCareEntrySearchResult} from ".";

export const useSoonestSlotForResult = (
  care: ExtendedCareEntrySearchResult,
  selectedLocation?: RootStateLocation,
  selectedRegion?: string,
) => {
  const isTopic = !care.appointment_reason;
  const {locationsSorted} = useTypedSelector(state => state.config);
  const slugsToCheck = useMemo(
    () => (isTopic ? care.cares?.map(care => care.slug).slice(0, 4) || [] : [care.slug]),
    [isTopic, care.cares, care.slug],
  );

  return useSoonestSlotByReasonSlugsQuery(
    slugsToCheck,
    selectedLocation,
    selectedRegion,
    !locationsSorted,
  );
};
